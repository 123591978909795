import { mutate } from 'swr';
import {
  ApiHook,
  buildUrlQuery,
  useCreateSWREndpoint,
} from './use-swr-endpoint';
import { useCurrentUser } from './use-user';
import { PaymentMethod } from '@models/payment-method';

export interface SavedPaymentMethodsParams {
  country_of_use?: string | null;
  saved_for_later?: boolean;
}

export const useForceRefreshSavedPaymentMethods = () => () => {
  mutate(
    (key: any) =>
      typeof key === 'string' && key.startsWith(`/api/v3/payment_methods`),
    undefined,
    { revalidate: true }
  );
};

export const useSavedPaymentMethods = ({
  country_of_use,
  saved_for_later,
}: SavedPaymentMethodsParams): ApiHook<PaymentMethod[]> => {
  const { data: user } = useCurrentUser();
  const query = buildUrlQuery({
    'filter[country_of_use]': country_of_use ? country_of_use : undefined,
    'filter[saved_for_later]': saved_for_later,
  });
  const url = `/api/v3/payment_methods${query}`;
  const shouldFetch = !!user;
  return useCreateSWREndpoint<PaymentMethod[]>({
    url,
    shouldFetch,
    options: { keepPreviousData: true },
  });
};
