import { mutate } from 'swr';
import {
  ApiHook,
  buildUrlQuery,
  QueryParams,
  useCreateSWREndpoint,
} from './use-swr-endpoint';
import { useCurrentUser } from './use-user';
import { Payment } from '@models/payment';
import { PaymentSchedule } from '@models/payment-schedule';
import { PaymentPagination } from '@models/pagination';
import { Organization } from '@models/organization';

interface PaymentHistoryProps {
  id?: string;
}

export const useGetPayment = ({
  id,
}: PaymentHistoryProps): ApiHook<Payment> => {
  const { data: user } = useCurrentUser();
  const url = `/api/v3/payments/${id}`;
  const isGuestUser = user?.status === 'guest';
  const shouldFetch = !!user && !!id && !isGuestUser;
  return useCreateSWREndpoint<Payment>({ url, shouldFetch });
};

export const useGetPaymentOrganizations = (): ApiHook<Organization[]> => {
  const { data: user } = useCurrentUser();
  const url = `/api/v3/current_user/payment_organizations`;
  const shouldFetch = !!user;
  return useCreateSWREndpoint<Organization[]>({ url, shouldFetch });
};

export const useGetUpcomingPayment = (): ApiHook<PaymentSchedule[]> => {
  const { data: user } = useCurrentUser();
  const url = `/api/v3/payment_schedules?filter[period]=once`;
  const shouldFetch = !!user;
  return useCreateSWREndpoint<PaymentSchedule[]>({ url, shouldFetch });
};

export const mutatePayments = () => {
  mutate(
    (key) => typeof key === 'string' && key.startsWith(`/api/v3/payments`)
  );
};

const buildPaymentsQuery = ({
  orgId,
  createdOnOrAfter,
  createdOnOrBefore,
  page = 1,
  pageSize = 15,
}: AllPaymentHistoryProps) => {
  const queryParams: QueryParams = {
    'filter[org_id]': orgId,
    created_on_or_after: createdOnOrAfter,
    created_on_or_before: createdOnOrBefore,
    'page[number]': page,
    'page[size]': pageSize,
  };
  return buildUrlQuery(queryParams);
};

interface AllPaymentHistoryProps {
  orgId?: string; // can be a list comma separated
  createdOnOrAfter?: string;
  createdOnOrBefore?: string;
  page?: number;
  pageSize?: number;
}
export const useGetPayments = ({
  orgId,
  createdOnOrAfter,
  createdOnOrBefore,
  pageSize,
  page = 1,
}: AllPaymentHistoryProps): ApiHook<PaymentPagination> => {
  if (page < 1) {
    page = 1;
  }
  const { data: user } = useCurrentUser();
  const query = buildPaymentsQuery({
    orgId,
    createdOnOrAfter,
    createdOnOrBefore,
    page,
    pageSize,
  });
  const url = `/api/v3/payments${query}`;
  const shouldFetch = !!user;
  return useCreateSWREndpoint<PaymentPagination>({ url, shouldFetch });
};

export const useGetPaymentSchedule = ({
  id,
}: PaymentHistoryProps): ApiHook<PaymentSchedule> => {
  const { data: user } = useCurrentUser();
  const url = `/api/v3/payment_schedules/${id}`;
  const isGuestUser = user?.status === 'guest';
  const shouldFetch = !!user && !!id && !isGuestUser;
  return useCreateSWREndpoint<PaymentSchedule>({ url, shouldFetch });
};

export const mutatePaymentSchedule = (id: string) => {
  mutate(`/api/v3/payment_schedules/${id}`);
};

export const mutatePaymentSchedules = () => {
  mutate(
    (key) =>
      typeof key === 'string' && key.startsWith(`/api/v3/payment_schedules`)
  );
};

export const mutateUpcomingPayments = () => {
  mutate(`/api/v3/payment_schedules?filter[period]=once`);
};

interface AllPaymentScheduleProps {
  orgId: string;
  period: string;
}
export const useGetPaymentSchedules = ({
  orgId,
  period,
}: AllPaymentScheduleProps): ApiHook<PaymentSchedule[]> => {
  const { data: user } = useCurrentUser();
  const query = buildUrlQuery({
    'filter[org_id]': orgId,
    'filter[period]': period,
  });
  const url = `/api/v3/payment_schedules${query}`;
  const shouldFetch = !!user;
  return useCreateSWREndpoint<PaymentSchedule[]>({ url, shouldFetch });
};

export const useGetPaymentScheduleOrganizations = (): ApiHook<
  Organization[]
> => {
  const { data: user } = useCurrentUser();
  const url = `/api/v3/current_user/payment_schedule_organizations`;
  const shouldFetch = !!user;
  return useCreateSWREndpoint<Organization[]>({ url, shouldFetch });
};
