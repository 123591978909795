import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import currency, { default as Currency } from 'currency.js';
import { useRouter } from 'next/router';
import { getCsrfToken, useSession } from 'next-auth/react';
import omit from 'lodash.omit';
import { useIntl } from 'react-intl';
import groupBy from 'lodash.groupby';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import uniq from 'lodash.uniq';
import {
  FormErrors,
  getIntlErrorMessage,
  setResponseErrorMessages,
  validateRequiredFields,
} from './use-form-validation';
import {
  initializePaymentMethodFormData,
  PaymentMethodFormData,
  validatePaymentMethodForm,
} from './use-payment-method-form';
import { useCurrentUser } from './use-user';
import { saveNewPaymentMethod } from './use-payment-method-form';
import {
  getOrganizationFinancialCountry,
  useGetOrganization,
} from './use-organization';
import { useOnline } from './use-online';
import {
  mutatePaymentSchedule,
  mutatePaymentSchedules,
} from './use-payment-history';
import { useRecaptcha } from './use-recaptcha';
import { useSavedPaymentMethods } from './use-saved-payment-methods';
import { PaymentCategoryType } from '@models/payment-category';
import { PaymentMethod, PaymentMethodTemp } from '@models/payment-method';
import {
  PaymentSchedule,
  PaymentSchedulePeriod,
} from '@models/payment-schedule';
import { PaymentMethodType } from '@models/payment-method-type';
import { CurrentUser } from '@models/user';
import { OrgFinancialCountry, Organization } from '@models/organization';
import { fetchPatch, fetchPost } from '@fetch/helpers';
import { PaymentRequestLineItem } from '@models/http/create-payment-request';
import { CreatePaymentResponse } from '@models/http/create-payment-response';

export interface TitheEnvelopeLineItem {
  amount: string;
  name: string;
}

export interface TitheFormEnvelopeValues {
  [key: string]: TitheEnvelopeLineItem;
}

export type TitheFormTotals = {
  [key in PaymentCategoryType]: Currency;
};

export type TitheFormEnvelopeLineItems = {
  [key in PaymentCategoryType]: TitheFormEnvelopeValues;
};

export type TitheFormData = {
  line_items: TitheFormEnvelopeLineItems;
  period?: Omit<PaymentSchedulePeriod, 'once'>;
  one_time_future_date?: string;
  start_date?: string;
  end_date?: string;
  paymentMethodId?: string;
  paymentMethodType?: PaymentMethodType;
  tempPaymentMethod?: PaymentMethodTemp;
  paymentType?: TitheFormPaymentType;
  firstTimePaymentMethod: PaymentMethodFormData;
  confirmationEmail?: boolean;
  acceptedPrivacyPolicy?: boolean;
  uuid: string;
};

export type TitheFormPaymentType = 'OneTime' | 'Recurring';

export const MIN_GRAND_TOTAL = currency(1.25);
export const MAX_GRAND_TOTAL = currency(20000);

const validateGrandTotal = (grandTotal: Currency) => {
  if (grandTotal.intValue < MIN_GRAND_TOTAL.intValue) {
    return `Total must be at least ${MIN_GRAND_TOTAL.format()}`;
  }
  if (grandTotal.intValue > MAX_GRAND_TOTAL.intValue) {
    return `Total cannot exceed ${MAX_GRAND_TOTAL.format()}`;
  }
};

const validatePaymentForm = (values: TitheFormData) => {
  let errors: FormErrors<TitheFormData> = {};
  if (!values.paymentMethodId) {
    errors.paymentMethodId = { type: 'required' };
  } else {
    if (
      values.paymentMethodId === 'TempCard' ||
      values.paymentMethodId === 'TempBank'
    ) {
      if (!values.tempPaymentMethod) {
        errors.paymentMethodId = { type: 'required' };
      }
    }
  }
  if (!values.paymentType) {
    errors.paymentType = { type: 'required' };
  }
  if (!values.acceptedPrivacyPolicy) {
    errors.acceptedPrivacyPolicy = { type: 'privacyPolicy' };
  }
  if (values.paymentType === 'Recurring') {
    errors = {
      ...errors,
      ...validateRequiredFields(['period'], values),
    };
  }
  const useFirstTimePaymentMethod = values.paymentMethodId === 'FirstTime';
  if (useFirstTimePaymentMethod) {
    delete errors.paymentMethodId;
    errors = {
      ...errors,
      ...validatePaymentMethodForm({ values: values.firstTimePaymentMethod })
        .errors,
    };
  }
  const useNewMethod =
    values.paymentMethodId === 'TempCard' ||
    values.paymentMethodId === 'TempBank';
  if (useNewMethod) {
    delete errors.paymentMethodId;
    errors = {
      ...errors,
      ...validatePaymentMethodForm({
        values: omit(values.tempPaymentMethod, 'id'),
      }).errors,
    };
  }
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

const validateReviewForm = (values: TitheFormData) => {
  let errors: FormErrors<TitheFormData> = {};
  if (values.paymentType === 'Recurring') {
    if (!values.paymentMethodId) {
      errors.paymentMethodId = { type: 'required' };
    }
    errors = {
      ...errors,
      ...validateRequiredFields(['period'], values),
    };
  }
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

/**
 * There is only one scenario for which the currency is hidden.
 * When the user has a USA mailing address and they are making a donation
 * to an organization in the USA. Otherwise, we show the currency.
 */
export const shouldShowCurrency = (
  user?: CurrentUser,
  organization?: Organization
) => {
  if (!user || !organization) {
    return true;
  }
  return user.country !== 'USA' || organization.country !== 'USA';
};

const initializeValues = (
  orgId: string,
  sessionReady = false,
  targetCountry?: OrgFinancialCountry,
  paymentSchedule?: PaymentSchedule
) => {
  const lineItemMap = {
    tithe: {},
    church: {},
    'church.parent': {},
    world: {},
  };
  const recurringLineItemsMap = paymentSchedule
    ? groupBy(paymentSchedule.line_items, 'payment_category.payment_group.id')
    : {};
  const recurringLineItems = Object.keys(lineItemMap).reduce(
    (tally, category) => {
      if (!recurringLineItemsMap[category]) {
        return {
          [category]: {},
          ...tally,
        };
      }
      return {
        [category]: recurringLineItemsMap[category].reduce(
          (items, item) => ({
            [item.payment_category.id]: {
              amount: currency(item.amount / 100).toString(),
              name: item.name,
            },
            ...items,
          }),
          {}
        ),
        ...tally,
      };
    },
    {} as TitheFormEnvelopeLineItems
  );
  const formData: TitheFormData = {
    line_items: recurringLineItems || lineItemMap,
    period: '',
    start_date: '',
    end_date: '',
    paymentMethodId: '',
    tempPaymentMethod: undefined,
    firstTimePaymentMethod: initializePaymentMethodFormData(
      targetCountry,
      targetCountry === 'Canada' ? 'CreditCard' : 'ElectronicCheck'
    ),
    paymentType: 'OneTime',
    confirmationEmail: undefined,
    acceptedPrivacyPolicy: undefined,
    uuid: uuidv4(),
  };
  if (!sessionReady) {
    return formData;
  }
  if (paymentSchedule) {
    return formData;
  }

  const lineItemsStr = sessionStorage.getItem(`donate_${orgId}_line_items`);
  if (lineItemsStr) {
    const lineItems = JSON.parse(lineItemsStr);
    for (const _key in formData.line_items) {
      const key = _key as PaymentCategoryType;
      formData.line_items[key] = lineItems[key] || formData.line_items[key];
    }
  }
  const methodId = sessionStorage.getItem(`donate_${orgId}_payment_method_id`);
  if (methodId) {
    // only set a temp method if it still exists
    // i.e. it won't exist after a browser refresh
    if (methodId === 'TempCard' || methodId === 'TempBank') {
      if (!!formData.tempPaymentMethod) {
        formData.paymentMethodId = methodId;
      }
    } else {
      formData.paymentMethodId = methodId;
    }
  }
  const paymentType = sessionStorage.getItem(`donate_${orgId}_payment_type`);
  if (paymentType) {
    formData.paymentType = paymentType as TitheFormPaymentType;
  }
  const paymentMethodType = sessionStorage.getItem(
    `donate_${orgId}_payment_method_type`
  );
  if (paymentMethodType) {
    formData.paymentMethodType = paymentMethodType as PaymentMethodType;
  }
  const start_date = sessionStorage.getItem(`donate_${orgId}_start_date`);
  if (start_date) {
    formData.start_date = start_date;
  }
  const end_date = sessionStorage.getItem(`donate_${orgId}_end_date`);
  if (end_date) {
    formData.end_date = end_date;
  }
  const period = sessionStorage.getItem(`donate_${orgId}_period`);
  if (period) {
    formData.period = period as PaymentSchedulePeriod;
  }
  const one_time_future_date = sessionStorage.getItem(
    `donate_${orgId}_one_time_future_date`
  );
  if (one_time_future_date) {
    formData.one_time_future_date = one_time_future_date;
  }
  const confirmationEmail = sessionStorage.getItem(
    `donate_${orgId}_confirmation_email`
  );
  if (confirmationEmail) {
    formData.confirmationEmail = confirmationEmail === 'yes';
  }
  const acceptedPrivacyPolicy = sessionStorage.getItem(
    `donate_${orgId}_privacy_policy`
  );
  if (acceptedPrivacyPolicy) {
    formData.acceptedPrivacyPolicy = acceptedPrivacyPolicy === 'yes';
  }
  const uuid = sessionStorage.getItem(`donate_${orgId}_uuid`);
  if (uuid) {
    formData.uuid = uuid;
  }
  return formData;
};

const clearSessionStorage = (orgId: string) => {
  sessionStorage.removeItem(`donate_${orgId}_line_items`);
  sessionStorage.removeItem(`donate_${orgId}_payment_method_id`);
  sessionStorage.removeItem(`donate_${orgId}_payment_type`);
  sessionStorage.removeItem(`donate_${orgId}_payment_method_type`);
  sessionStorage.removeItem(`donate_${orgId}_start_date`);
  sessionStorage.removeItem(`donate_${orgId}_end_date`);
  sessionStorage.removeItem(`donate_${orgId}_period`);
  sessionStorage.removeItem(`donate_${orgId}_one_time_future_date`);
  sessionStorage.removeItem(`donate_${orgId}_confirmation_email`);
  sessionStorage.removeItem(`donate_${orgId}_privacy_policy`);
  sessionStorage.removeItem(`donate_${orgId}_totals`);
  sessionStorage.removeItem(`donate_${orgId}_grand_total`);
  sessionStorage.removeItem(`donate_${orgId}_uuid`);
};

export const clearUserSpecificSessionStorage = () => {
  const canRun = typeof window !== 'undefined';
  if (!canRun) {
    return;
  }
  const orgIdsStr = sessionStorage.getItem('donate_org_ids');
  if (!orgIdsStr) {
    return;
  }
  const orgIds = JSON.parse(orgIdsStr);
  if (!Array.isArray(orgIds)) {
    return;
  }

  for (const orgId of orgIds) {
    sessionStorage.removeItem(`donate_${orgId}_confirmation_email`);
    sessionStorage.removeItem(`donate_${orgId}_payment_method_id`);
    sessionStorage.removeItem(`donate_${orgId}_payment_method_type`);
  }
};

interface TotalsParams {
  orgId: string;
  values: TitheFormData;
  totals: TitheFormTotals;
  setTotals: Dispatch<SetStateAction<TitheFormTotals>>;
  setGrandTotal: Dispatch<SetStateAction<Currency>>;
  setGrandTotalError: Dispatch<SetStateAction<string | undefined>>;
}

const updateTotals = ({
  orgId,
  values,
  totals,
  setTotals,
  setGrandTotal,
  setGrandTotalError,
}: TotalsParams) => {
  const _totals: TitheFormTotals = {
    tithe: currency(0),
    church: currency(0),
    'church.parent': currency(0),
    world: currency(0),
  };
  for (const cat in totals) {
    const category = cat as PaymentCategoryType;
    const total = Object.keys(values.line_items[category]).reduce(
      (prev, current) => {
        return prev.add(values.line_items[category][current].amount);
      },
      currency(0)
    );
    _totals[category] = total;
  }
  const _grandTotal = Object.keys(_totals).reduce((prev, current) => {
    return prev.add(_totals[current as PaymentCategoryType]);
  }, currency(0));
  setTotals(_totals);
  setGrandTotal(_grandTotal);
  setGrandTotalError(validateGrandTotal(_grandTotal));
  sessionStorage.setItem(`donate_${orgId}_totals`, JSON.stringify(_totals));
  sessionStorage.setItem(
    `donate_${orgId}_grand_total`,
    JSON.stringify(_grandTotal)
  );
};

const filterLineItemsCategoryForRequest = (
  lineItems: TitheFormEnvelopeValues
) => {
  return Object.keys(lineItems)
    .map((key) => ({
      payment_category_id: key,
      amount: currency(lineItems[key].amount).intValue,
    }))
    .filter((i) => i.amount > 0);
};

const getLineItemsForRequest = (values: TitheFormData) => {
  const line_items: PaymentRequestLineItem[] = Object.keys(
    values.line_items
  ).reduce((prev, next) => {
    const category = next as PaymentCategoryType;
    const mappedItems = filterLineItemsCategoryForRequest(
      values.line_items[category]
    );
    return [...prev, ...mappedItems];
  }, [] as PaymentRequestLineItem[]);
  return line_items;
};

const createPayment = async (
  values: TitheFormData,
  organization_id: string,
  payment_method_id: string,
  send_confirmation_email: boolean,
  csrfToken?: string
) => {
  const line_items = getLineItemsForRequest(values);
  const response = await fetchPost({
    url: '/api/v3/payments',
    data: {
      line_items,
      organization_id,
      send_confirmation_email,
      payment_method_id,
      csrfToken,
    },
    headers: { 'x-payment-token': values.uuid },
  });
  return {
    response,
    data: response.ok && ((await response.json()) as CreatePaymentResponse),
  };
};

const createPaymentSchedule = async (
  values: TitheFormData,
  organization_id: string,
  payment_method_id: string,
  period: PaymentSchedulePeriod,
  csrfToken?: string,
  start_date?: string,
  end_date?: string
) => {
  const line_items = getLineItemsForRequest(values);
  return fetchPost({
    url: `/api/v3/payment_schedules`,
    data: {
      period,
      start_date: start_date || dayjs().add(1, 'day').format('YYYY-MM-DD'),
      end_date: end_date || undefined,
      line_items,
      payment_method_id,
      organization_id,
      csrfToken,
    },
    headers: { 'x-payment-token': values.uuid },
  });
};

type CurrentStep = 'envelope' | 'payment' | 'review' | 'recurring_update';
const useTitheForm = (
  currentStep: CurrentStep,
  orgId: string,
  paymentSchedule?: PaymentSchedule
) => {
  if (currentStep === 'recurring_update' && !paymentSchedule) {
    throw new Error('Missing payment schedule for recurring doantion.');
  }
  const titheTotals: TitheFormTotals = {
    tithe: currency(0),
    church: currency(0),
    'church.parent': currency(0),
    world: currency(0),
  };
  const { data: organization } = useGetOrganization({ id: orgId });
  const { data: paymentMethods } = useSavedPaymentMethods({});

  const targetCountry =
    organization && getOrganizationFinancialCountry(organization);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [values, setValues] = useState(
    initializeValues(orgId, false, targetCountry)
  );

  const [totals, setTotals] = useState(titheTotals);
  const [grandTotal, setGrandTotal] = useState(currency(0));
  const [grandTotalError, setGrandTotalError] = useState<string>();
  const [errors, setErrors] = useState<FormErrors<TitheFormData>>();
  const [hasErrors, setFormHasErrors] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string[]>();
  const { status: sessionStatus } = useSession();
  const { data: user } = useCurrentUser();

  const router = useRouter();
  const intl = useIntl();
  const isOnline = useOnline();
  const { checkRecaptcha } = useRecaptcha({
    action: 'donationForm',
    setIsSubmitting,
    setSubmitError,
  });

  const updateValues = (newValues: Partial<TitheFormData>) => {
    const _values = {
      ...values,
      ...newValues,
    };
    setValues(_values);
    updateTotals({
      orgId,
      values: _values,
      totals,
      setTotals,
      setGrandTotal,
      setGrandTotalError,
    });
    sessionStorage.setItem(
      `donate_${orgId}_line_items`,
      JSON.stringify(_values.line_items)
    );
    sessionStorage.setItem(
      `donate_${orgId}_payment_method_id`,
      _values.paymentMethodId || ''
    );
    _values.paymentType &&
      sessionStorage.setItem(
        `donate_${orgId}_payment_type`,
        _values.paymentType
      );
    sessionStorage.setItem(
      `donate_${orgId}_payment_method_type`,
      _values.paymentMethodType || ''
    );
    sessionStorage.setItem(
      `donate_${orgId}_start_date`,
      _values.start_date || ''
    );
    sessionStorage.setItem(`donate_${orgId}_end_date`, _values.end_date || '');
    sessionStorage.setItem(
      `donate_${orgId}_period`,
      _values.period?.toString() || ''
    );
    sessionStorage.setItem(
      `donate_${orgId}_one_time_future_date`,
      _values.one_time_future_date || ''
    );
    if (_values.confirmationEmail !== undefined) {
      sessionStorage.setItem(
        `donate_${orgId}_confirmation_email`,
        _values.confirmationEmail ? 'yes' : 'no'
      );
    }
    if (_values.acceptedPrivacyPolicy !== undefined) {
      sessionStorage.setItem(
        `donate_${orgId}_privacy_policy`,
        _values.acceptedPrivacyPolicy ? 'yes' : 'no'
      );
    }
    sessionStorage.setItem(`donate_${orgId}_uuid`, _values.uuid);
    const orgIdsStr = sessionStorage.getItem('donate_org_ids');
    if (!orgIdsStr) {
      sessionStorage.setItem('donate_org_ids', JSON.stringify([orgId]));
    } else {
      const orgIds = JSON.parse(orgIdsStr);
      if (Array.isArray(orgIds)) {
        sessionStorage.setItem(
          'donate_org_ids',
          JSON.stringify(uniq([orgId, ...orgIds]))
        );
      } else {
        sessionStorage.setItem('donate_org_ids', JSON.stringify([orgId]));
      }
    }
  };

  if (hasInitialized && user && values.confirmationEmail === undefined) {
    updateValues({
      ...values,
      confirmationEmail: user.send_confirmation_email,
    });
  }

  if (hasInitialized && user && values.acceptedPrivacyPolicy === undefined) {
    updateValues({
      ...values,
      acceptedPrivacyPolicy: user.accepted_privacy_policy,
    });
  }

  if (
    currentStep === 'payment' &&
    hasInitialized &&
    !values.paymentMethodId &&
    paymentMethods?.length
  ) {
    const paymentMethodId =
      paymentMethods[0].country_of_use === targetCountry
        ? paymentMethods[0].id
        : '';
    if (paymentMethodId) {
      updateValues({
        ...values,
        paymentMethodId,
      });
    }
  }

  const genericSubmitFail = () => {
    setSubmitError([getIntlErrorMessage('processingError', intl)]);
    setIsSubmitting(false);
  };

  const handleSubmitFromEnvelope = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    const error = validateGrandTotal(grandTotal);
    setGrandTotalError(error);
    if (error) {
      setIsSubmitting(false);
      return;
    }

    if (currentStep === 'recurring_update') {
      if (!paymentSchedule) {
        setIsSubmitting(false);
        setSubmitError([getIntlErrorMessage('processingError', intl)]);
        return;
      }

      const csrfToken = await getCsrfToken();
      const line_items = getLineItemsForRequest(values);
      const response = await fetchPatch({
        url: `/api/v3/payment_schedules/${paymentSchedule.id}`,
        data: { line_items, csrfToken },
      });
      if (response.ok) {
        mutatePaymentSchedules();
        mutatePaymentSchedule(paymentSchedule.id);
        router.push({
          pathname: '/account/recurring-donations',
          query: {
            id: paymentSchedule.id,
          },
        });
      } else {
        setIsSubmitting(false);
        setResponseErrorMessages({ response, setErrors, setSubmitError, intl });
      }
    } else {
      const nextPageUrl = router.query.review
        ? `/donate/${orgId}/review`
        : !user
          ? `/donate/${orgId}/login`
          : `/donate/${orgId}/payment`;
      router.push(nextPageUrl);
      setIsSubmitting(false);
    }
  };

  const handleSaveNewPaymentMethod = async (
    tempPaymentMethod: PaymentMethodTemp | PaymentMethodFormData,
    savedForLater: boolean,
    recaptcha: string,
    successCallback: () => void
  ) => {
    const response = await saveNewPaymentMethod(
      tempPaymentMethod as any,
      savedForLater,
      recaptcha
    );
    if (response.ok) {
      const data: PaymentMethod = await response.json();
      updateValues({
        ...values,
        paymentMethodId: data.id,
        tempPaymentMethod: undefined,
      });
      successCallback();
    } else {
      setResponseErrorMessages({ response, setErrors, setSubmitError, intl });
      setIsSubmitting(false);
    }
  };

  /**
   * There are a few possible scnearios at this point.
   *
   * Registered users
   * 1. A registered user makes a one-time or recurring donation with a saved payment method
   *    In this event, no request is made on this page and they go to the review page.
   * 2. A registered user adds a new payment method for a one-time or recurring donation
   *    with the "save credit card for later" checked.
   *    In this event we need to save the credit card for later, but make no payment requests.
   * 3. A registered user makes a one-time payment with a new payment method with
   *    the "save credit card for later" unchecked.
   *    In this event, we need create a pending payment if one does not exist yet (see edit ability in step 4)
   *    and an authorized payment before going to the next page.
   * 4. A registered user edits their payment from step 3. In this scenario we have a pending
   *    payment already. The user will fill out their new card details.
   *    In this event, we will bypass the pending payment request and make a new call to the authorized
   *    endpoint.
   * 5. A registered user edits their payment from step 3 but this time they change from one-time to recurring
   *    In this event, we will cancel the pending payment (pending API completion), and follow
   *    either step 1 or step 2.
   *
   * Guest users
   * 1. A guest makes a one-time payment. Guests never have saved payment methods.
   *    In this event we need to make a pending payment and an authorized payment before going to
   *    the next page.
   * 2. A guest edits their one-time payment that has already been authorized.
   *    In this even we should already have a pending payment and only need to call the authorized payment
   *    again before going to the next page.
   */
  const handleSubmitFromPayment = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    const token = await checkRecaptcha();
    if (!token) {
      return;
    }

    const _errors = validatePaymentForm(values);
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('formValidationErrors', intl)]);
      return;
    }

    if (sessionStatus !== 'authenticated' || !user) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('unauthenticated', intl)]);
      return;
    }

    setIsSubmitting(true);
    try {
      const goToNextPage = () => {
        router.push(`/donate/${orgId}/review`);
      };

      const isRecurring =
        values.paymentType === 'Recurring' || !!values.one_time_future_date;
      const useFirstTimePaymentMethod = values.paymentMethodId === 'FirstTime';
      let _savedPaymentMethodId = values.paymentMethodId;
      let _isAddingNewCard =
        useFirstTimePaymentMethod ||
        _savedPaymentMethodId === 'TempCard' ||
        _savedPaymentMethodId === 'TempBank';
      const newPaymentMethod = useFirstTimePaymentMethod
        ? values.firstTimePaymentMethod
        : values.tempPaymentMethod;

      if (_isAddingNewCard) {
        if (!newPaymentMethod) {
          genericSubmitFail();
          return;
        }
        await handleSaveNewPaymentMethod(
          newPaymentMethod,
          isRecurring ? true : newPaymentMethod.saved_for_later!,
          token,
          goToNextPage
        );
      } else {
        // Scenario 1: we are done, processing is all done on the next page.
        await goToNextPage();
      }
    } catch (err) {
      genericSubmitFail();
    }
  };

  /**
   * This is the final page before the payment is captured. Here are the scnearios.
   *
   * Registered user
   * 1. Making a one-time payment with a payment method (transient or saved).
   * 2. Making a recurring payment.
   *    This is similar to scenario 1 but requires a different endpoint.
   *
   * Guest user
   * 1. Guests can only make one-time payments with new cards.
   *    This should be the same as registered user's scenario 1.
   */
  const handleSubmitFromReview = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    const _errors = validateReviewForm(values);
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setSubmitError([getIntlErrorMessage('processingError', intl)]);
      setIsSubmitting(false);
      return;
    }

    if (sessionStatus !== 'authenticated' || !user) {
      setSubmitError([getIntlErrorMessage('unauthenticated', intl)]);
      setIsSubmitting(false);
      return;
    }

    // TODO: handle unverified users?
    const isRegisteredUser = user.status !== 'guest';
    const isGuestUser = user.status === 'guest';
    const csrfToken = await getCsrfToken();
    try {
      const goToNextPage = (paymentId: string, isRecurring: boolean) => {
        clearSessionStorage(orgId);
        const query = isRecurring
          ? `recurringId=${paymentId}`
          : `id=${paymentId}`;
        router.push(`/donate/confirmation?${query}`);
      };

      const handleCapture = async (
        paymentMethodId: string,
        confirmationEmail: boolean
      ) => {
        const { response, data } = await createPayment(
          values,
          orgId,
          paymentMethodId,
          confirmationEmail,
          csrfToken
        );
        if (response.ok && data) {
          if (isGuestUser) {
            sessionStorage.setItem(`payment_${data.id}`, JSON.stringify(data));
          }
          goToNextPage(data.id, false);
        } else {
          setIsSubmitting(false);
          setResponseErrorMessages({
            response,
            setErrors,
            setSubmitError,
            intl,
          });
        }
      };

      const isRecurring =
        values.paymentType === 'Recurring' || !!values.one_time_future_date;
      let _savedPaymentMethodId = values.paymentMethodId;

      const privacyResponse = await fetchPatch({
        url: `/api/v3/current_user`,
        data: { accepted_privacy_policy: true, csrfToken },
      });
      if (!privacyResponse.ok) {
        setResponseErrorMessages({
          response: privacyResponse,
          setErrors,
          setSubmitError,
          intl,
        });
        setIsSubmitting(false);
        return;
      }

      if (isRegisteredUser) {
        if (isRecurring) {
          if (!_savedPaymentMethodId) {
            setSubmitError([
              getIntlErrorMessage('recurringNeedPaymentMethodError', intl),
            ]);
            setIsSubmitting(false);
            return;
          }
          // scenario 2
          const startDate =
            values.paymentType === 'Recurring'
              ? values.start_date
              : values.one_time_future_date;
          const period = values.one_time_future_date ? 'once' : values.period;
          if (!period) {
            setSubmitError([
              getIntlErrorMessage('recurringIntervalError', intl),
            ]);
            setIsSubmitting(false);
            return;
          }
          const response = await createPaymentSchedule(
            values,
            orgId,
            _savedPaymentMethodId,
            period as PaymentSchedulePeriod,
            csrfToken,
            startDate,
            values.end_date
          );
          if (response.ok) {
            const PaymentSchedule: PaymentSchedule = await response.json();
            goToNextPage(PaymentSchedule.id, true);
          } else {
            setResponseErrorMessages({
              response,
              setErrors,
              setSubmitError,
              intl,
            });
            setIsSubmitting(false);
          }
        } else {
          // scneario 1
          if (!_savedPaymentMethodId) {
            setSubmitError([getIntlErrorMessage('paymentAPIError', intl)]);
            setIsSubmitting(false);
            return;
          }
          await handleCapture(
            _savedPaymentMethodId,
            values.confirmationEmail || false
          );
        }
      } else if (isGuestUser) {
        // Guest only has 1 scenario
        if (!_savedPaymentMethodId) {
          setSubmitError([getIntlErrorMessage('paymentAPIError', intl)]);
          setIsSubmitting(false);
          return;
        }
        await handleCapture(
          _savedPaymentMethodId,
          values.confirmationEmail || false
        );
      } else {
        genericSubmitFail();
      }
    } catch (err) {
      genericSubmitFail();
    }
  };

  useEffect(() => {
    if (currentStep === 'payment') {
      const _errors = validatePaymentForm(values);
      setErrors(_errors.errors);
      setFormHasErrors(_errors.hasErrors);
    } else if (currentStep === 'review') {
      const _errors = validateReviewForm(values);
      setErrors(_errors.errors);
      setFormHasErrors(_errors.hasErrors);
    }
  }, [values, currentStep]);

  useEffect(() => {
    if (hasInitialized) {
      return;
    }

    if (orgId && targetCountry) {
      const values = initializeValues(
        orgId,
        true,
        targetCountry,
        paymentSchedule
      );
      setValues(values);
      updateTotals({
        orgId,
        values,
        totals,
        setTotals,
        setGrandTotal,
        setGrandTotalError,
      });
      setHasInitialized(true);
    }
  }, [hasInitialized, orgId, totals, targetCountry, paymentSchedule]);

  const resetHasSubmitted = () => {
    setHasSubmitted(false);
    setSubmitError(undefined);
  };

  return {
    paymentMethods,
    values,
    handleSubmitFromEnvelope,
    handleSubmitFromPayment,
    handleSubmitFromReview,
    hasSubmitted,
    resetHasSubmitted,
    isSubmitting,
    updateValues,
    totals,
    grandTotal,
    grandTotalError,
    errors,
    hasErrors,
    submitError,
    hasInitialized,
  };
};

export default useTitheForm;
